.carousel__container {
  /* max-width: 100%; */
  /* margin: auto; */
  /* border: 2px solid black; */
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 7%;
  padding-right: 7%;
  /* border: 2px solid rgb(181, 231, 243); */
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  width: calc(100% - 5%);
  height: 100%;
}

.swimlane-container-dock {
  position: relative;
  max-width: 100%;
  /*height: 14rem;*/
  z-index: 10;
}
